export default {
  breadCrumb: "Home",
  btnClick: "Click",
  btnClose: "Close",
  btnSearch: "Search",
  btnSearchClear: "Search Clear",
  btnReadMore: "Read More",
  btnWatchMore: "More",
  btnProductCompare: "Product Compare",
  btnDistributor: "Distributor",
  btnDownloadBrochure: "Download Brochure",
  btnCompare: "Compare",
  btnSendMessage: "Send Message",
  btnContactHR: "Contact to HR Department",
  btnWatchMap: "Watch AGC Map",
  btnEnterSite: "Enter Site",
  contact: "More Information and We Will Contact You",
  headers: {
    home: "Home",
    product: "Product",
    aboutUs: "About Us",
    footPrint: "Footprint",
    map:"AGC Map",
    saleChannel: "Sales Channel",
    technicalSupport: "Technical Support",
    newsAndArticles: "News",
    sustainability: "Sustainability",
    contactUs: "Contact Us",
    search: "Search",
    subProducts: {
      productAGC: "AGC Products",
      clearFloatGlass: "Clear Float Glass",
      underclearFloatGlass: {
        clearFloatGlass: "Clear Float Glass",
      },
      floatGlass: "Tinted Float Glass",
      underFloatGlass: {
        floatGlass: "Tinted Glass",
        oceanGreenFloatGlass: "Ocean Green Float Glass",
        energyGreenFloatGlass: "Energy Green Float Glass",
        darkGreyFloatGlass: "Dark Grey Float Glass",
        euroGreyFloatGlass: "Euro Grey Float Glass",
        euroBronzeFloatGlass: "Euro Bronze Float Glass",
        skyBlueFloatGlass: "Sky Blue Float Glass",
      },
      coatingGlass: "Coating Glass",
      underCoatingGlass: {
        solarTAGPlus: "Heat Reflective - SolarTAG Plus",
        highEfficiencyLowE: "High Efficiency Low-E - Stopray",
      },
      mirrorGlass: "Mirror",
      underMirrorGlass: {
        mirrorMirroTAG: "Mirror - MirroTAG",
      },
      processedGlass: "Processed Glass",
      underProcessedGlass: {
        temperedGlassTemTAG: "Tempered Glass TempTAG",
        insulatedGlassPairTAG: "Insulated Glass PairTAG",
        laminatedGlassLamiTAG: "Laminated Glass LamiTAG",
      },
      specialProduct: "Special Product",
      underSpecialProduct: {
        sunEwatBIPV: "SunEwat",
      },
      compare: "Product Compare",
      findYourGlass: "Find Your Glass",
    },
    subSustainability: {
      Sustainability: "Sustainability",
      AFTSustainabilityOverview: "AFT Sustainability Overview",
      environmentalManagementSystem: "Environmental Management System",
      productionProcessAndTechnologies: "Production Process And Technologies",
      environmentalAchievements: "Environmental Achievements",
      environmentFriendlyProducts: "Environment Friendly Products",
      globalEnvironmentalFootprint: "Global Environmental Footprint",
      sustainableBuildings: "Sustainable Buildings",
      UNSustainabilityDevelopmentGoals: "UN Sustainability Development Goals",
    },
  },
  footer: {
    addresses: {
      companyName: "Head Office Samutprakarn Factory",
      address:
        "200 Moo 1, Suksawas Rd., Pak Khong Bang Pla Kod, Phra Sumut Chedi, Samut Prakan 10290",
      time: "Opening Hours : 8.00-17.00",
      telephone: "Tel. ",
      fax: "Fax",
    },
    product: "Product",
    aboutUs: "About Us",
    footPrint: "Footprint",
    saleChannel: "Sales Channel",
    technicalSupport: "Technical Support",
    newsAndArticles: "News",
    sustainability: "Sustainability",
    contactUs: "Contact Us",
    topicSubscribe: "Never miss News and New Idea",
    placeHolderSubscribe: "Please Email",
    copyRight:
      "Copyright 2023 AGC Flatglass (Thailand) Co., Ltd. All rights reserved.",
    privacyPolicy: "Privacy Policy",
    subAboutUs: {
      companyHistory: "Company History",
      executiveVision: "Vision",
      chronology: "Chronology",
    },
    subProducts: {
      clearFloatGlass: "Clear Float Glass",
      floatGlass: "Tinted Float Glass",
      coatingGlass: "Coating Glass",
      mirrorGlass: "Mirror",
      processedGlass: "Processed Glass",
      sunEwatBIPV: "SunEwat",
      compare: "Product Compare",
      findYourGlass: "Find Your Glass",
    },
    subSustainability: {
      AFTSustainabilityOverview: "AFT Sustainability Overview",
      environmentalManagementSystem: "Environmental Management System",
      productionProcessAndTechnologies: "Production Process And Technologies",
      environmentalAchievements: "Environmental Achievements",
      environmentFriendlyProducts: "Environment Friendly Products",
      globalEnvironmentalFootprint: "Global Environmental Footprint",
      sustainableBuildings: "Sustainable Buildings",
      UNSustainabilityDevelopmentGoals: "UN Sustainability Development Goals",
    },
    subContactUs: {
      sendMessage: "Send me a message",
      faq: "FAQ",
    },
    subFootprint: {
      officeBuildings: "Office Building",
      home: "Home",
      map: "AGC Map",
    },
    subTechnicalSupport: {
      glassConfigurator: "Glass Configurator",
      download: "Download",
      lawAndRuleOfGlass: "Law and Rule of Glass",
    },
  },
  home: {
    title: "We Create Thai Glass Innovation",
    gradianTitle: "Thai Asahi Glass is AGC Flatglass",
    findYourGlass: {
      subTitle: "Find The Right Glass for You",
      lowBuilding: "Low-rise Building",
      highBuilding: "High-rise Building",
      glassType: "Glass Type",
      sheet: "Monolithic",
      laminated: "Laminated",
      insulated: "Insulated",
      laminatedInsulated: "Laminated+Insulated",
      color: "Color",
      clear: "Clear",
      green: "Green",
      darkGrey: "Dark Grey",
      skyBlue: "Sky Blue",
      grey: "Grey",
      bronzeGold: "Bronze/Gold",
      other: "Other",
      btnAdvancedSearch: "Advanced Search",
      properLight: "Proper Light",
      properHeatProtection: "Proper Heat Protection",
      safety: "Safety",
      UVProtection: "UV Protection",
      noiseReducer: "Noise Reducer",
    },
    product: {
      agcFlatglass: "AGC Flatglass",
    },
    sustainability: {
      agcFlatglassThailand: "AGC FLAT GLASS (THAILAND) PLC.",
      subTitle1: "Taking into account the responsibility in every process,",
      subTitle2: "we thus develop every production process to be safe for society, community and environment."
    },
  },
  product: {
    title: "AGC Products",
    agcGlass: "",
    btnDistributor: "Your Nearest Distributors",
    compare: {
      titleCompare: "Product Compare",
      productCompare: "",
    },
    findYourGlass: {
      subTitle: "Find The Right Glass for You",
      lowBuilding: "Low-rise Building",
      highBuilding: "High-rise Building",
      glassType: "Glass Type",
      sheet: "Monolithic",
      laminated: "Laminated",
      insulated: "Insulated",
      laminatedInsulated: "Laminated+Insulated",
      color: "Color",
      clear: "Clear",
      green: "Green",
      darkGrey: "Dark Grey",
      skyBlue: "Sky Blue",
      grey: "Grey",
      bronzeGold: "Bronze/Gold",
      other: "Other",
      btnAdvancedSearch: "Advanced Search",
      properLight: "Proper Light",
      properHeatProtection: "Proper Heat Protection",
      safety: "Safety",
      UVProtection: "UV Protection",
      noiseReducer: "Noise Reducer",
    },
    content: {
      features: "Features",
      usage: "Usage",
    },
  },
  findYourGlass: {
    subTitle: "Find The Right Glass for You",
    lowBuilding: "Low-rise Building",
    highBuilding: "High-rise Building",
    glassType: "Glass Type",
    sheet: "Monolithic",
    laminated: "Laminated",
    insulated: "Insulated",
    laminatedInsulated: "Laminated+Insulated",
    color: "Color",
    clear: "Clear",
    green: "Green",
    darkGrey: "Dark Grey",
    skyBlue: "Sky Blue",
    grey: "Grey",
    bronzeGold: "Bronze/Gold",
    other: "Other",
    btnAdvancedSearch: "Advanced Search",
    properLight: "Proper Light",
    properHeatProtection: "Proper Heat Protection",
    safety: "Safety",
    UVProtection: "UV Protection",
    noiseReducer: "Noise Reducer",
    titleResult: "Result The Right Glass for You or Nearest",
    operationType: "Operation Type",
    protect: "Protect",
    list: "lists",
    technicalSupport: "Technical Support",
    properProtection: "Proper Protection",
    heat: "Heat",
    allBuilding: "Low-rise Building and High-rise Building",
  },
  compare: {
    productCompare: "Product Compare",
    model: "model",
    selectThickness: "select thickness",
    unitmm: "mm",
    operationType: "Operation Type",
    allBuilding: "Low-rise Building and High-rise Building",
    lowBuilding: "Low-rise Building",
    highBuilding: "High-rise Building",
    protect: "Protect",
    contact: "Get help from experts",
  },
  sustainability: {
    title: "Sustainability",
  },
  news: {
    title: "News",
    content: {
      postDate: "Post Date",
    },
  },
  footprint: {
    title: "Footprint",
    title2: "",
    officeBuilding: "Office Building",
    home: "Home",
    content: {
      buildName: "Build Name",
      type: "Type",
      province: "Province",
      country: "Country",
      glassProduct: "Glass Product",
      glassType: "Glass Type",
      usage: "Usage",
      architect: "Architect",
      installer: "Installer",
    }
  },
  saleChannel: {
    title: "Sales Channel",
    region: "Region",
    province: "Province",
    keywordSearch: "Keyword Search",
    placeHolderCompanyOrAdderss: "Company or Address",
    title2: "The Store You are Looking for or Nearby shops",
    all: "All",
  },
  technicalSupport: {
    title: "Technical Support",
    glassConfigurator: "Glass Configurator",
    detail: "An online tool that will help you draft a mirror design that is suitable for your project for finding suitable and most effective AGC glass products. The reports including necessary glass technical data are ready to be downloaded to be included in the design of the glass appropriately with your project.",
    download: "Download",
    subDownload: "Gather Esentials about Glass",
    law: "Law",
    subLaw: "and Rule of Glass",
  },
  contactUs: {
    title: "Contact Us",
    companyName: "AGC FLAT GLASS (THAILAND) PLC.",
    telephone: "Tel.",
    fax: "Fax",
    addresses1: {
      companyName:
        "Head Office Samutprakarn Factory",
      address:
        "200 Moo 1, Suksawas Rd., Pak Khong Bang Pla Kod, Phra Sumut Chedi, Samut Prakan 10290",
      time: "Opening Hours : 8.00 - 17.00",
    },
    addresses2: {
      companyName:
        "Amata City Chon Buri Factory",
      address:
        "700/22 Moo 6, Nong Mai Daeng, Mueang Chon Buri, Chon Buri 20000",
      time: "Opening Hours : 8.00 - 17.00",
    },
    addresses3: {
      companyName:
        "Amata City Rayong Factory",
      address:
        "7/104 Moo 4, Hoghway No.331, Map Yong Phon, Pluk Daeng, Rayong 21140",
      time: "Opening Hours : 8.00 - 17.00",
    },
    contactToSale: "Contact to Sales Department",
    fullName: "Name-Surname",
    company: "Company",
    occupation: "Occupation",
    email: "E-Mail",
    tel: "Telephone Number",
    interestProduct: "Interested Product",
    topicContact: "Topic Contact",
    selectMoreThanOne: "Select More Than One",
    message: "Message",
    acceptPrivacy: "Accept the terms of service",
    headPrivacy: "Letter of Consent for Personal Data Provision",
    linePrivacy1: "Customers can understand the privacy policy of AGC at the website www.agc-flatglass.co.th.",
    linePrivacy2: "Customers can ask for details or complaints to the Personal Data Protection Officer.",
    linePrivacy3: "AGC will retain customer data for 10 years after the customer stops using AGC products and services.",
    linePrivacy4: "Customers can withdraw consent at AGC Contact Tel. 02 815 5000 or other new channels as announced on the Company’s website.",
    faq: "FAQ",
    placeholderCaptcha:"Please enter your security code."
  },
  aboutUs: {
    title: "Our Mission",
    subTitle: "“Look Beyond” To make the world a brighter place.",
    name: "About Us",
    companyProfile: "Company Profile",
    detail: `
    AGC Flat Glass (Thailand) Plc. is the first flat glass manufacturer in Thailand. Established in 1963 under the former name of “Thai Asahi Glass” as the join venture of Thai Investor and Asahi Glass (Japan).
    More than 5 decades as leading glass manufacturer in Thailand. Produces quality flat glass such as float glass, Tinted float glass, Mirror, Heat reflective glass, Low-E glass, Tempered glass, Laminated safety glass, Insulating glass, Several kinds of specialty glass including glass for energy conservation to supply to construction (housing & building), processor, fabricated industry and automobile glass manufacturer both domestic and export customer in the region and around the world.
    We intend to provide and serve all customer needs and requirements with high quality of glass and service according to the agreement under the philosophy of
    <br><br>
    “Look Beyond to make the world a brighter place.”`,
    startTitle: "AGC Glass started for more 100 years in Japan.",
    startTitle2: "AGC is running business more than 60 year in Thailand and expanding the business more than 30 countries worldwide.",
    visionTitle: "Vision",
    livisionTitle: "We shall be the most trusted regional No.1 Company to lead Sustainable Development of the Region by providing our Technologies and Solutions.",
    historyTitle: "Milestone",
    history: {
      head1: "1963",
      detail1: "Mr. Kiat Srifuengfung has established the flat glass company where is the first flat glass company in Thailand. The company name was ‘Thai Glass Co., Ltd.",
      head2: "1964",
      detail2: `Asahi Glass Co., Ltd. Joined Thai Glass Co., Ltd. And renamed as Thai-Asahi Glass Co., Ltd. (TAG)`,
      head3: "1984",
      detail3: `The 1st Float Glass Factory in Thailand to produce Float Glass with Float process.`,
      head4: "1985",
      detail4: `TAG started to produce the mirror glass “MIRROTAG”`,
      head5: "1988",
      detail5: `TAG has produced the high quality of the Heat Reflective Glass by using Magnetic sputtering Process “SOLARTAG”`,
      head6: "1989",
      detail6: `Registered in stock exchange of Thailand as Thai-Asahi Glass Public Company Limited.`,
      head7: "1991",
      detail7: `Bangkok Float Glass Co., Ltd. Started to go on process. Construction of production facilities to produce flat tempered glass “TEMPTAG”`,
      head8: "1995",
      detail8: `Started to process Insulated Glass Unit Line in Thailand “PAIRTAG”`,
      head9: "1996",
      detail9: `TAG was certified for quality of management international ISO9002 by BVQI and named TAG the only one manufacturer of glass industry in Thailand that has ISO for total production processes of product lines from 5 institutes in 6 countries.`,
      head10: "1997",
      detail10: `Started Rayong Factory 1997`,
      head11: "2002",
      detail11: `Consolidation of TAG and BFG to be one company with 3 factories Samut Prakan, Rayong and Chon Buri under the name “TAG”`,
      head12: "2006",
      detail12: `Started to produce Laminated safety glass line in Thailand. “LAMITAG”`,
      head13: "2007",
      detail13: `In order to unify the Group brand globally under “Asahi Glass Co., Ltd.” The company name in English and logo was changed to AGC Flat glass (Thailand) Plc. 100th Anniversary celebration of AGC Japan.`,
      head14: "2011",
      detail14: `Started to product Laminated Low-E safety glass “LAMITAG-E” as glass for Energy Conservation.`,
      head15: "2013",
      detail15: `50th Anniversary of AGC Flat glass (Thailand) Plc.`,
      head16: "2014",
      detail16: `Started and launched energy saving glass “ENERGY GREEN” to the market.`,
      head17: "2015",
      detail17: `Increase production capacity of Solar Control and started to produce Low-E Glass “STOPRAY”`,
      head18: "2018",
      detail18: `55th Anniversary for the big major flat glass manufacturer.`,
      head19: "2019",
      detail19: `Thai-Asahi Glass Public Company Limited, had changed name to AGC Flat glass (Thailand) Pcl.`,
    }
  },
  maps: {
    title: "AGC Map",
    subTitle: "60 projects of pride",
    subTitle2: "more than 60 years",
    region: "Region",
    province: "Province",
    typeBuild: "Type Building",
    typeGlass: "Type Glass",
  },
  toolbar: {
    title: "Search on AGC FLAT GLASS (THAILAND)",
    hit: "Popular Search",
    placeholder: "search"
  },
  search: {
    title: "Searching results",
    result: "Results for your",
    placeholder: "Enter the search keyword",
    notFoundGlass: "We couldn't find any glass information.",
    notFoundDistribution: "The area was not found However, you can utilize the Service to locate nearby shops."
  }
};
